import React, { createContext, useState, useEffect } from "react";
import ReactDOM from "react-dom/client";

import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

import { App } from "./App";
import "./style/scroller.scss";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

// Отримуємо емейли адміністраторів з .env
const adminEmails = [
  process.env.REACT_APP_ADMIN_I,
  process.env.REACT_APP_ADMIN_II,
];

// Створюємо контекст
export const Context = createContext(null);

const AppProvider = ({ children }) => {
  const [localClaims, setLocalClaims] = useState({});
  const [userData, setUserData] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  // 🔹 Функція для оновлення userData в контексті та Firestore
  const updateUserData = async (newData) => {
    setUserData((prevData) => {
      const updatedData = { ...prevData, ...newData };
      // console.log("🔄 Оновлено userData:", updatedData);
      return updatedData;
    });
  };

  const fetchUserData = async (uid) => {
    try {
      const userRef = doc(firestore, "users", uid);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        console.log("Користувача не знайдено. Новий користувач?");
        return;
      }

      let fetchedUserData = userSnap.data();

      // Отримуємо прогрес студента
      const progressRef = doc(firestore, `student-progress/${uid}`);
      const progressSnap = await getDoc(progressRef);

      if (progressSnap.exists()) {
        fetchedUserData = {
          ...fetchedUserData,
          studentProgress: progressSnap.data(),
        };
      } else {
        console.log("📌 Прогрес користувача не знайдено.");
      }

      // Отримуємо результати тестування
      const quizzesRef = doc(firestore, `quizzes_results/${uid}`);
      const quizzesSnap = await getDoc(quizzesRef);

      if (quizzesSnap.exists()) {
        fetchedUserData = {
          ...fetchedUserData,
          testResults: quizzesSnap.data().quizzes || {},
        };
      } else {
        // console.log("📌 Результати тестування не знайдено.");
      }

      setUserData(fetchedUserData);
    } catch (error) {
      console.error("❌ Помилка отримання даних користувача: ", error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult(true);
        setLocalClaims(idTokenResult.claims);

        await fetchUserData(user.uid);

        // Перевіряємо, чи є користувач адміністратором
        setIsAdmin(adminEmails.includes(user.email));
      } else {
        setLocalClaims({});
        setUserData(null);
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();
  }, []);

  // console.log(userData);

  return (
    <Context.Provider
      value={{
        auth,
        storage,
        firestore,
        functions,
        localClaims,
        setLocalClaims,
        userData,
        updateUserData,
        isAdmin,
      }}
    >
      {children}
    </Context.Provider>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(
  <AppProvider>
    <App />
  </AppProvider>
);
