import ReactDOMServer from "react-dom/server";
import React from "react";
import { collection, addDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from "react-toastify";

// utils
import { generateDocument } from "../fileHandlers/docxTemplater";
import { formatDateTime } from "../textHandlers/date";

// Components
import { WelcomeUA, WelcomeUAText } from "../../database/emails/WelcomeUA";
import {
  WelcomeUA25,
  WelcomeUA25Text,
} from "../../database/emails/WelcomeUA25";
import {
  WelcomeUA100,
  WelcomeUA100Text,
} from "../../database/emails/WelcomeUA100";
import { InstallUA, InstallUAText } from "../../database/emails/InstallmentUA";

// 🛠 Допоміжна функція для конвертації файлу у base64
const getBase64FromStorage = async (storage, filePath) => {
  try {
    const downloadURL = await getDownloadURL(ref(storage, filePath));
    // console.log("📥 File download URL:", downloadURL);
    const response = await fetch(downloadURL);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        let base64data = reader.result.split(",")[1]; // Видаляємо "data:application/..."
        base64data = base64data.replace(/\r\n/g, "").replace(/\n/g, ""); // 🔥 Видаляємо зайві переноси рядків
        // console.log(
        //   "📝 Cleaned Base64 (first 100 chars):",
        //   base64data.substring(0, 100)
        // );
        resolve(base64data);
      };
      reader.onerror = (error) => {
        console.error("❌ Error reading file as base64:", error);
        reject(error);
      };
    });
  } catch (error) {
    console.error("❌ Error fetching file from Firebase Storage:", error);
    return null;
  }
};

export const SendEmail = async (firestore, storage, newUserData, emailType) => {
  const mailCollection = collection(firestore, "mail");
  const emailAddresses =
    newUserData.relativeEmail && newUserData.relativeEmail !== "Не вказано"
      ? [newUserData.email, newUserData.relativeEmail]
      : [newUserData.email];

  // Визначаємо кінцеву ціну курсу
  let amountDue = 1200; // За замовчуванням - повна ціна
  if (emailType === "discount100") {
    amountDue = 87; // Якщо 100% знижка, сума до оплати - лише реєстраційний внесок
  }

  // Визначаємо payment terms
  const payTerms =
    emailType === "installments"
      ? "Payment in equal instalments of EUR 120 over 10 months"
      : "100% advance payment";

  // const unsubscribeLink = `https://us-central1-niderlands-3bd21.cloudfunctions.net/unsubscribeEmail?email=${newUserData.email}`;
  // const unsubscribeLink = `https://us-central1-niderlands-3bd21.cloudfunctions.net/unsubscribeEmail?email=${encodeURIComponent(
  //   newUserData.email
  // )}`;

  // Формуємо пропси для email
  const emailProps = {
    name: newUserData.displayName || "Студент",
    email: newUserData.email,
    fullPrice: 1200,
    discountPrice: 1200 * 0.75,
    registrationFee: 87,
    amountDue, // Передаємо amountDue
    payTerms, // Передаємо payTerms
  };

  // Визначаємо, який шаблон email використовувати
  let emailHtml, emailText;

  if (emailType === "discount25") {
    emailHtml = ReactDOMServer.renderToStaticMarkup(
      <WelcomeUA25 {...emailProps} />
    );
    emailText = WelcomeUA25Text(emailProps);
  } else if (emailType === "discount100") {
    emailHtml = ReactDOMServer.renderToStaticMarkup(
      <WelcomeUA100 {...emailProps} />
    );
    emailText = WelcomeUA100Text(emailProps);
  } else if (emailType === "installments") {
    // Новий варіант для розстрочки
    emailHtml = ReactDOMServer.renderToStaticMarkup(
      <InstallUA {...emailProps} />
    );
    emailText = InstallUAText(emailProps);
  } else {
    emailHtml = ReactDOMServer.renderToStaticMarkup(
      <WelcomeUA {...emailProps} />
    );
    emailText = WelcomeUAText(emailProps);
  }

  try {
    // ✅ Генеруємо документ та отримуємо `blob`
    const blob = await generateDocument(
      newUserData,
      emailProps,
      "invoice",
      firestore
    );

    console.log("📄 Document successfully generated");

    // 📝 Формуємо шлях до файлу
    const newDate = new Date();
    const formattedDate = `${String(newDate.getDate()).padStart(
      2,
      "0"
    )}.${String(newDate.getMonth() + 1).padStart(
      2,
      "0"
    )}.${newDate.getFullYear()}`;
    const filePath = `invoices/${newUserData.displayName}-${formattedDate}.docx`;

    // ✅ Завантажуємо файл у Firebase Storage
    const storageRef = ref(storage, filePath);
    await uploadBytes(storageRef, blob);
    // console.log("📤 File uploaded to Storage:", filePath);

    // ✅ Отримуємо Base64-вміст файлу
    const base64data = await getBase64FromStorage(storage, filePath);

    const emailData = {
      to: emailAddresses,
      message: {
        subject: "International Academy MBO",
        text: emailText,
        html: emailHtml,
        attachments: base64data
          ? [
              {
                filename: "invoice.docx",
                content: base64data,
                mimeType:
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              },
            ]
          : [],
      },
    };

    // console.log(
    //   "📩 Preparing to send email with attachments:",
    //   emailData.message.attachments
    // );

    await addDoc(mailCollection, emailData);
    // console.log("📨 Email with attachment added to Firestore.");
    toast.success("Check your email");
  } catch (error) {
    console.error("❌ Error adding registration email:", error);
    toast.error("Error adding registration email.");
  }
};

// Активація промокоду після сабміту
export const markPromoAsUsed = async (firestore, promoCode) => {
  if (!promoCode) return;

  if (!/^\d{5}-\d$/.test(promoCode)) {
    console.warn(`⚠️ Невірний формат промокоду: ${promoCode}`);
    return;
  }

  const [promoId, promoValue] = promoCode.split("-");
  const promoRef = doc(firestore, "promo", promoId);

  try {
    const promoSnap = await getDoc(promoRef);
    if (!promoSnap.exists()) throw new Error("Promo code not found");

    const promoData = promoSnap.data();
    let availableCodes = promoData.available
      ? promoData.available.split(", ")
      : [];
    let activeCodes = promoData.active ? promoData.active.split(", ") : [];

    // Перевіряємо, чи код дійсно доступний
    if (!availableCodes.includes(promoValue)) {
      throw new Error("Promo code not available");
    }

    // Переносимо код з available у active
    availableCodes = availableCodes.filter((code) => code !== promoValue);
    activeCodes.push(promoValue);

    // Оновлюємо документ промокоду
    await updateDoc(promoRef, {
      available: availableCodes.join(", "),
      active: activeCodes.join(", "),
    });

    console.log(`✅ Промокод ${promoCode} успішно активовано`);
  } catch (error) {
    console.error(`🚨 Помилка активації промокоду: ${error.message}`);
    throw error;
  }
};
