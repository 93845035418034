import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

// hooks
import { Context } from "../../index";
import { RandomTimeout } from "../../utils/textHandlers/date";

// Assets
import ringTone from "../../lib/mp3/incoming-message.mp3";
import ringTone2 from "../../lib/mp3/happy-popup.mp3";

// Styles
import "./qPopupStyle.scss";

export const QuestionarePopup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { auth } = useContext(Context);
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const popupSeen = sessionStorage.getItem("popup");
    if (!popupSeen) {
      const initialDelay = RandomTimeout(15, 20);
      const timeoutId = setTimeout(() => {
        setIsVisible(true);
      }, initialDelay);

      return () => clearTimeout(timeoutId);
    }
  }, [auth.currentUser]);

  const closePopup = () => {
    const audio = new Audio(ringTone2);
    audio.play();
    setIsVisible(false);

    // Зберігаємо прапорець у sessionStorage
    sessionStorage.setItem("popup", "true");
  };

  const handleZoomRedirect = () => {
    // const audio = new Audio(ringTone);
    // audio.play();
    window.location.href =
      "https://us05web.zoom.us/j/4362200643?pwd=DahoLAbMp8Kv4sSnay1QpND6VWa3C9.1";
  };

  if (!isVisible) return null;

  return (
    <main className="popup" onClick={handleZoomRedirect}>
      <section className="popup__content">
        <div className="popup__text">{t("zoom_meeting_info")}</div>
        <div className="popup__text">{t("zoom_join")}</div>
      </section>

      <div
        className="popup__wrap"
        onClick={(e) => {
          e.stopPropagation();
          closePopup();
        }}
      >
        <button className="popup__close-btn">&times;</button>
      </div>
    </main>
  );
};
