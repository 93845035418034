import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DownloadFile } from "../../utils/fileHandlers/downloadFile";

// utils
import { ourEmail } from "../../database/commonData";
import "./footerStyle.scss";

import googleMapImg from "../../lib/logo-icons/google-maps.png";
import emailIcon from "../../lib/email.png";
import supportIcon from "../../lib/support.png";

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    const googleMapsUrl =
      "https://www.google.com/maps/place/Piet+Heinkade+55,+1019+GM+Amsterdam,+%D0%9D%D1%96%D0%B4%D0%B5%D1%80%D0%BB%D0%B0%D0%BD%D0%B4%D0%B8/@52.3775264,4.9152734,17z";
    window.open(googleMapsUrl, "_blank"); // Відкрити у новій вкладці
  };

  return (
    <main className="footer" id="contacts">
      <section className="footer__col">
        <p className="footer__title">International Academy MBO</p>

        <div className="footer__row" onClick={handleClick}>
          <img
            className="footer__icon big"
            src={googleMapImg}
            alt="address-icon"
          />

          <div className="footer__col">
            <p className="footer__text footer__text--underline">
              UP Office Building
            </p>
            <p className="footer__text">Piet Heinkade 55</p>
            <p className="footer__text">1019 GM Amsterdam</p>
          </div>
        </div>
      </section>

      <section className="footer__col">
        <p className="footer__title">{t("contacts")}</p>
        <div className="footer__row left">
          <img className="footer__icon" src={emailIcon} alt="email-icon" />
          <a className="footer__text" href={`mailto:${ourEmail}`}>
            {ourEmail}
          </a>
        </div>
        <div className="footer__row left" onClick={() => navigate("/support")}>
          <img className="footer__icon" src={supportIcon} alt="support-icon" />
          <a className="footer__link">{t("support")}</a>
        </div>
      </section>

      <section className="footer__col">
        <p className="footer__title">{t("documents")}</p>
        <div className="footer__text footer__text--btn row">
          <DownloadFile />
        </div>
        {/* <p className="footer__text">{t("extract_from_kvk")}</p> */}
      </section>
      {/* <SocialsComponent /> */}

      <div className="footer__divider"></div>

      <p className="footer__copy">
        © {currentYear}. {"  "} INTERNATIONAL ACADEMY MBO. KVK-nummer 88463192.
        {"  "}
        {t("all_rights_reserved")}.
      </p>
    </main>
  );
};

const SocialsComponent = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="footer__col">
        <p className="footer__title">{t("social_media")}</p>
        <div className="footer__linkwrap">
          {/* <a href="https://www.facebook.com/groups/390578963845505">
            <img
              className="footer__icon"
              src="https://cdn-icons-png.flaticon.com/512/174/174848.png"
              alt="img-facebook"
            />
          </a> */}
          <a href="https://t.me/+Hy8uWITdllxhZWQ6">
            <img
              className="footer__icon"
              src="https://cdn-icons-png.flaticon.com/512/2111/2111646.png"
              alt="img-telegram"
            />
          </a>
        </div>
      </div>
    </>
  );
};
