import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import FileSaver from "file-saver";
import JSZipUtils from "jszip-utils";
import { toast } from "react-toastify";
import { generateInvoiceNumber } from "../textHandlers/generateInvoiceNumber";
import { formatDate } from "../textHandlers/date";
import infoFile from "../../database/info.docx";
import invoiceFile from "../../database/invoice.docx";

// Функція для вибору файлу
const getFile = (documentType) => {
  const fileMap = {
    invoice: invoiceFile,
    info: infoFile,
  };

  if (!fileMap[documentType]) {
    console.error(`❌ Invalid document type: ${documentType}`);
    return null;
  }

  return fileMap[documentType];
};

export async function generateDocument(
  userData,
  emailProps,
  documentType,
  firestore
) {
  return new Promise((resolve, reject) => {
    let file = getFile(documentType);
    if (!file) return reject("Invalid document type");

    JSZipUtils.getBinaryContent(file, async (error, content) => {
      if (error) {
        console.error("❌ Error loading template file:", error);
        toast.error("Помилка при завантаженні файлу");
        return reject(error);
      }

      try {
        const invoiceNumber =
          documentType === "invoice"
            ? await generateInvoiceNumber(firestore)
            : null;

        const zip = new PizZip(content);
        const doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
        });

        const data = {
          displayName: userData.displayName,
          selectedCourse: userData.selectedCourse,
          phoneNumber: userData.phoneNumber,
          email: userData.email,
          fullAddress: userData.fullAddress,
          birthDate: userData.birthDate,

          relativeName: userData.relativeDisplayName || "Не вказано",
          relativePhone: userData.relativePhoneNumber || "Не вказано",
          relativeBirthDate: userData.relativeBirthDate || "Не вказано",
          relativeEmail: userData.relativeEmail || "Не вказано",
          relativeFullAddress: userData.relativeFullAddress || "Не вказано",

          payerName:
            userData.relativeDisplayName &&
            userData.relativeDisplayName !== "Не вказано"
              ? userData.relativeDisplayName
              : userData.displayName,
          payerAddress:
            userData.relativeFullAddress &&
            userData.relativeFullAddress !== "Не вказано"
              ? userData.relativeFullAddress
              : userData.fullAddress,
          payerEmail:
            userData.relativeEmail && userData.relativeEmail !== "Не вказано"
              ? userData.relativeEmail
              : userData.email,

          invoiceNumber,
          invoiceDate: formatDate(new Date()),
          regDate: userData.regDate || "2025 рік",
          premiumStatus: userData.premium ? "Так" : "Ні",

          ...emailProps,
        };

        await doc.renderAsync(data);

        const blob = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });

        // console.log("📄 Document successfully generated:", documentType);
        resolve(blob);
      } catch (error) {
        console.error("❌ Rendering error:", error);
        toast.error(`Помилка: ${error.message}`);
        reject(error);
      }
    });
  });
}
