import React, { useState, useEffect, useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";

import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import "../../utils/i18n";

// Context
import { Context } from "../../index";

// Utils
import { openHandler, closeHandler } from "../../utils/dom/open-close-modal";
import { useScrollPosition, scrollToTop } from "../../utils/dom/scroll";

// Components
import { SubHeaderComponent } from "./subHeader";
import { QuestionarePopup } from "../../Modals/questionarePopup/qPopup";
import { LoginModal } from "../../Modals/auth/auth";
import { Burger } from "./burger";

// Assets
import BackImg from "../../lib/logo-icons/backArrow2.png";
import loginImg from "../../lib/login-img/login5.png";
import logoImg from "../../lib/logo-icons/logo2.png";
import defAva from "../../lib/noname-ava2.png";
import admImg from "../../lib/admin.png";
import spinGif from "../../lib/Spinner.gif";

// Styles
import "./headerStyle.scss";

const flags = [
  { lang: "en", label: "English" },
  { lang: "de", label: "Deutsch" },
  { lang: "nl", label: "Nederlands" },
  { lang: "es", label: "Español" },
  { lang: "pt", label: "Português" },
  { lang: "ua", label: "Українська" },
  { lang: "ar", label: "العربية" },
  { lang: "tr", label: "Türkçe" },
  { lang: "ru", label: "Русский" },
  { lang: "id", label: "Indonesia" },
  { lang: "zh", label: "中文" },
];

export const Header = ({ mod, type }) => {
  const navigate = useNavigate();
  const headerResize = useScrollPosition();
  const [showTitle, setShowTitle] = useState(true);
  const [isLesson, setIsLesson] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (type === "page") {
      setShowTitle(false);
    } else if (type === "lesson") {
      setIsLesson(true);
    }
  }, []);

  const handleLogoClick = () => {
    navigate("/");
    scrollToTop();
  };

  return (
    <section className="headers">
      <div
        className={`header ${
          headerResize ? "header--short" : "header--normal"
        }`}
      >
        <img
          className="header__logo"
          onClick={handleLogoClick}
          src={logoImg}
          alt="MBO-logo"
        />

        {mod !== "main" && (
          <section className="headerNavBar">
            <img
              className="header__back-arrow"
              onClick={() => {
                navigate(isLesson ? "/cabinet" : "/");
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              src={BackImg}
              alt="backBtn"
            />

            {showTitle && <div className="headerNavBar__title">{t(mod)}</div>}
          </section>
        )}

        <AuthComponent headerResize={headerResize} mod={mod} />

        <button
          className={`scrollBtn ${headerResize ? "visible" : "hidden"}`}
          onClick={scrollToTop}
        >
          <span className={`scrollBtn__arrow`}></span>
        </button>

        <QuestionarePopup />
      </div>

      {mod === "main" && <SubHeaderComponent />}
    </section>
  );
};

const AuthComponent = ({ headerResize, mod }) => {
  const auth = getAuth();
  const [user, loading, error] = useAuthState(auth);
  const [showAvatarMenu, setShowAvatarMenu] = useState(false);
  const { isAdmin } = useContext(Context);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState({
    login: false,
    acc: false,
    adm: false,
  });

  useEffect(() => {
    if (error) {
      toast.error(t("error_occurred"));
    }
  }, [error, t]);

  const handleOpenModal = (modalType) => {
    openHandler();
    setShowModal((prev) => ({
      ...prev,
      [modalType]: true,
    }));
    if (modalType !== "login") {
      setShowAvatarMenu(false);
    }
  };

  const handleCloseModal = (modalType) => {
    closeHandler();
    setShowModal((prev) => ({
      ...prev,
      [modalType]: false,
    }));
  };

  const handleMenubtnClick = () => {
    setShowAvatarMenu(false);
    navigate("/cabinet");
  };
  const handleMouseEnter = () => {
    setShowAvatarMenu(true);
  };
  const handleMouseLeave = () => {
    setShowAvatarMenu(false);
  };
  const handleLogout = () => {
    auth.signOut();
    setShowAvatarMenu(false);
    navigate("/");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Визначаємо статус автентифікації
  let authStatus;
  if (loading) {
    authStatus = "loading";
  } else if (user) {
    authStatus = "authenticated";
  } else {
    authStatus = "unauthenticated";
  }

  return (
    <section className="header__container">
      {isAdmin && (
        <img
          className="header__img2"
          src={admImg}
          onClick={() => navigate("/adm1n99")}
          alt="adminImg"
        />
      )}

      <SwitchBtn headerResize={headerResize} />

      <div
        className="header__wrap"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => setShowAvatarMenu(!showAvatarMenu)}
      >
        {(() => {
          switch (authStatus) {
            case "loading":
              return (
                <img className="header__logo" src={spinGif} alt="Loading..." />
              );
            case "authenticated":
              return (
                <img
                  className="header__logo"
                  src={user.photoURL || defAva}
                  alt="Avatar"
                />
              );
            case "unauthenticated":
            default:
              return (
                <img
                  className="header__logo header__logo--transparent"
                  onClick={() => handleOpenModal("login")}
                  src={loginImg}
                  alt="MBO-login"
                  id="login-btn"
                />
              );
          }
        })()}
      </div>

      {user && showAvatarMenu && (
        <div
          className="header__menu"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          id="login-btn"
        >
          <button className="header__btn2" onClick={handleMenubtnClick}>
            {t("cabinet")}
          </button>

          <button className="header__btn2" onClick={handleLogout}>
            {t("logout")}
          </button>
        </div>
      )}

      {mod === "main" && <Burger />}

      <LoginModal
        showLoginModal={showModal.login}
        setShowLoginModal={() => handleCloseModal("login")}
      />
    </section>
  );
};

function SwitchBtn() {
  const { i18n, t } = useTranslation();
  const [currentFlag, setCurrentFlag] = useState(0);
  const [showLangMenu, setShowLangMenu] = useState(false);

  useEffect(() => {
    // Отримання збереженої мови з cookies
    const savedLang = Cookies.get("lang") || "en";
    const foundIndex = flags.findIndex((flag) => flag.lang === savedLang);
    if (foundIndex !== -1) {
      setCurrentFlag(foundIndex);
      i18n.changeLanguage(savedLang);
    }
  }, [i18n]);

  const changeLanguage = (index) => {
    const selectedLang = flags[index].lang;

    // Забороняємо перемикання на мови без локалізації
    const unsupportedLanguages = ["zh", "es", "pt", "ar", "id", "tr"];
    if (unsupportedLanguages.includes(selectedLang)) {
      toast.info(t("sorry_language_not_supported"));
      return;
    }

    setCurrentFlag(index);
    i18n.changeLanguage(selectedLang);
    Cookies.set("lang", selectedLang, { expires: 7 }); // Збереження на 7 днів
    setShowLangMenu(false);
  };

  return (
    <div
      className="header__wrap"
      onClick={() => setShowLangMenu(!showLangMenu)}
      onMouseEnter={() => {
        setShowLangMenu(true);
      }}
      onMouseLeave={() => {
        setShowLangMenu(false);
      }}
    >
      <div className="header__flag">
        {flags[currentFlag].lang.toUpperCase()}
      </div>
      {showLangMenu && (
        <div
          className={`header__menu ${showLangMenu && "header__menu--large"}`}
        >
          {flags.map((flag, index) => (
            <div
              key={index}
              className={`header__menu-flag ${
                index === currentFlag && "header__btn2"
              }`}
              onClick={() => changeLanguage(index)}
            >
              {flag.label}
              <span className="header__menu-span"></span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
