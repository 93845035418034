import React, { useContext } from "react";
import { ref, getDownloadURL } from "firebase/storage";
import { useTranslation } from "react-i18next";
import { Context } from "../../index";
import docxImg from "../../lib/logo-icons/office--docx4.png";

export const DownloadFile = () => {
  const { storage } = useContext(Context);
  const { i18n, t } = useTranslation();

  const downloadFile = async () => {
    const currentLanguage = i18n.language; // Отримуємо поточну мову
    const fileNameMap = {
      ru: "offer-ru.docx",
      ua: "offer-ua.docx",
      en: "offer-en.docx",
    };
    const fileName = fileNameMap[currentLanguage] || fileNameMap.en;

    try {
      const storageRef = ref(storage, `offer/${fileName}`); // Створюємо посилання на файл у сховищі Firebase
      const url = await getDownloadURL(storageRef); // Отримуємо URL для завантаження
      const link = document.createElement("a"); // Створюємо посилання для завантаження
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Очищуємо після завантаження
    } catch (error) {
      console.error("Помилка при завантаженні файлу:", error);
    }
  };

  return (
    <div className="footer__row" onClick={downloadFile}>
      <img className="footer__icon" src={docxImg} alt="docxImg" />
      <p>{t("study_agreement")}</p>
    </div>
  );
};
