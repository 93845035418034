export const WelcomeUA100 = (props) => {
  const { name, registrationFee, email } = props;

  return (
    <html>
      <body
        style={{
          fontFamily: "Arial, sans-serif",
          lineHeight: "1.6",
          color: "black",
          padding: "20px",
          backgroundColor: "white",
          borderRadius: "10px",
          margin: "auto",
          width: "700px",
        }}
      >
        <img
          src="https://myplaylist2023.s3.eu-north-1.amazonaws.com/logo3.png"
          height="70px"
          width="70px"
          alt="Company Logo"
          style={{ marginBottom: "10px" }}
        />

        <div style={{ textAlign: "center" }}>
          <h1
            style={{
              fontSize: "22px",
              fontWeight: "bold",
              margin: "0",
              color: "black",
            }}
          >
            International Academy MBO
          </h1>
          <p
            style={{
              display: "inline-block",
              fontSize: "14px",
              marginTop: "5px",
              color: "black",
              lineHeight: "1.4",
            }}
          >
            1019 GM, Piet Heinkade 55, Amsterdam, Netherlands, &nbsp; email:{" "}
            <a
              href="mailto:post@inacademy.nl"
              style={{ color: "black", textDecoration: "none" }}
            >
              post@inacademy.nl,
            </a>
            &nbsp; website:{" "}
            <a
              href="https://www.inacademy.nl"
              style={{ color: "black", textDecoration: "none" }}
            >
              www.inacademy.nl
            </a>
          </p>
        </div>

        <hr
          style={{
            border: "none",
            borderBottom: "2px solid black",
            marginBottom: "20px",
          }}
        />

        <h2
          style={{
            textAlign: "center",
            fontSize: "20px",
            marginBottom: "20px",
          }}
        >
          {name}, вітаємо зі вступом до
          <br /> International Academy MBO.
        </h2>
        <p
          style={{
            marginBottom: "20px",
            textAlign: "justify",
          }}
        >
          Ми раді бачити вас серед наших студентів.
        </p>
        <p
          style={{
            marginBottom: "20px",
            textAlign: "justify",
          }}
        >
          Протягом наступних п’яти років ви можете опанувати нові знання,
          скласти екзамени та подати заявку на стажування в одній з європейських
          компаній.
        </p>
        <p
          style={{
            marginBottom: "20px",
            textAlign: "justify",
          }}
        >
          Щодо вартості: ви отримали{" "}
          <strong>грант на безкоштовне навчання</strong>. Ваш Договір про
          навчання активується після сплати{" "}
          <strong>{registrationFee} євро</strong> реєстраційного збору.
        </p>
        <p
          style={{
            marginBottom: "20px",
            textAlign: "justify",
          }}
        >
          Рахунок (Invoice) ми вже відправили на вашу електронну пошту.
        </p>
        <p
          style={{
            marginBottom: "20px",
            textAlign: "justify",
          }}
        >
          Бажаємо вам успіхів! Якщо будуть запитання – ми завжди на зв'язку.
        </p>
        <table style={{ width: "100%", marginTop: "30px" }}>
          <tbody>
            <tr>
              <td style={{ width: "50%", color: "black" }}>
                <p style={{ fontWeight: "bold", color: "black" }}>
                  З повагою,
                  <br />
                  Команда International Academy MBO
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <hr
          style={{
            border: "none",
            borderBottom: "1px solid gray",
            margin: "20px 0 5px 0",
          }}
        />

        <div
          style={{
            textAlign: "center",
            fontSize: "12px",
            lineHeight: "1.2",
            color: "gray",
          }}
        >
          If you no longer wish to receive our emails, <br /> you can{" "}
          <a
            href={`https://www.inacademy.nl/unsubscribe/${email}`}
            style={{
              textDecoration: "underline",
              fontWeight: "bold",
            }}
          >
            unsubscribe here
          </a>
          .
        </div>
      </body>
    </html>
  );
};

export const WelcomeUA100Text = (props) => {
  const { name, registrationFee, email } = props;

  return `
  ${name}, вітаємо зі вступом до International Academy MBO.

  Ми раді бачити вас серед наших студентів.

  Протягом наступних п’яти років ви можете опанувати нові знання, скласти екзамени та подати заявку на стажування в одній з європейських компаній.

  Щодо вартості: ви отримали грант на безкоштовне навчання. Ваш Договір про навчання активується після сплати ${registrationFee} євро реєстраційного збору.

  Рахунок (Invoice) ми вже відправили на вашу електронну пошту.

  Бажаємо успіхів! Якщо будуть запитання – ми завжди на зв'язку.

  З повагою,
  Команда International Academy MBO

  --------------------------------------------------------------
  If you no longer wish to receive our emails, you can unsubscribe using the following link:
  https://www.inacademy.nl/unsubscribe/${email}
  `;
};
