export const InstallUA = (props) => {
  const { name, email, fullPrice } = props;

  return (
    <html>
      <body
        style={{
          fontFamily: "Arial, sans-serif",
          lineHeight: "1.2",
          color: "black",
          padding: "20px",
          backgroundColor: "white",
          borderRadius: "10px",
          width: "700px",
          margin: "auto",
        }}
      >
        <img
          src="https://myplaylist2023.s3.eu-north-1.amazonaws.com/logo3.png"
          height="70px"
          width="70px"
          alt="Company Logo"
          style={{ marginBottom: "10px" }}
        />

        <div style={{ textAlign: "center" }}>
          <h1
            style={{
              fontSize: "22px",
              fontWeight: "bold",
              margin: "0",
              color: "black",
            }}
          >
            International Academy MBO
          </h1>
          <p
            style={{
              display: "inline-block",
              fontSize: "14px",
              marginTop: "5px",
              color: "black",
              lineHeight: "1.4",
            }}
          >
            1019 GM, Piet Heinkade 55, Amsterdam, Netherlands, &nbsp; email:{" "}
            <a
              href="mailto:post@inacademy.nl"
              style={{ color: "black", textDecoration: "none" }}
            >
              post@inacademy.nl,
            </a>
            &nbsp; website:{" "}
            <a
              href="https://www.inacademy.nl"
              style={{ color: "black", textDecoration: "none" }}
            >
              www.inacademy.nl
            </a>
          </p>
        </div>

        <hr
          style={{
            border: "none",
            borderBottom: "2px solid black",
            marginBottom: "20px",
          }}
        />

        <h2
          style={{
            textAlign: "center",
            fontSize: "20px",
            marginBottom: "20px",
            color: "black",
          }}
        >
          {name}, вітаємо!
        </h2>
        <p
          style={{
            textAlign: "justify",
          }}
        >
          Ваша розстрочка активована. Платіж <strong>{fullPrice} євро</strong>{" "}
          розбито на 10 місяців.
        </p>
        <p
          style={{
            textAlign: "justify",
          }}
        >
          В подальшому, ви сплачуєте по <strong>120 євро</strong> до 25 числа
          кожного поточного місяця.
        </p>
        <p
          style={{
            textAlign: "justify",
          }}
        >
          Ваш Договір про навчання почне діяти після здійснення першого платежу.
        </p>
        <p
          style={{
            textAlign: "justify",
          }}
        >
          Рахунок (Invoice) ми вже відправили на вашу електронну пошту.
        </p>
        <p
          style={{
            marginTop: "20px",
            textAlign: "justify",
          }}
        >
          Бажаємо успіхів! Якщо будуть запитання – ми завжди на зв'язку.
        </p>
        <table style={{ width: "100%", marginTop: "30px" }}>
          <tbody>
            <tr>
              <td style={{ width: "50%", color: "black" }}>
                <p style={{ fontWeight: "bold", color: "black" }}>
                  З повагою,
                  <br />
                  Команда International Academy MBO
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <hr
          style={{
            border: "none",
            borderBottom: "1px solid gray",
            margin: "20px 0 5px 0",
          }}
        />

        <div
          style={{
            textAlign: "center",
            fontSize: "12px",
            lineHeight: "1.2",
            color: "gray",
          }}
        >
          If you no longer wish to receive our emails, <br /> you can{" "}
          <a
            href={`https://www.inacademy.nl/unsubscribe/${email}`}
            style={{
              textDecoration: "underline",
              fontWeight: "bold",
            }}
          >
            unsubscribe here
          </a>
          .
        </div>
      </body>
    </html>
  );
};

export const InstallUAText = (props) => {
  const { name, email, fullPrice } = props;

  return `
  ${name}, вітаємо!

  Ваша розстрочка активована. Платіж ${fullPrice} євро розбито на 10 місяців.
  В подальшому, ви сплачуєте по 120 євро до 25 числа кожного поточного місяця.
  Ваш Договір про навчання почне діяти після здійснення першого платежу.
  Рахунок (Invoice) ми вже відправили на вашу електронну пошту.
  Бажаємо успіхів! Якщо будуть запитання – ми завжди на зв'язку.

  З повагою,
  Команда International Academy MBO

  --------------------------------------------------------------
  If you no longer wish to receive our emails, you can unsubscribe using the following link:
  https://www.inacademy.nl/unsubscribe/${email}
  `;
};
