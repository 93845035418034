import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { closeHandler } from "../../utils/dom/open-close-modal";
import { Context } from "../../index";
import { toast } from "react-toastify";
import openEye from "../../lib/input-img/open2.png";
import closeEye from "../../lib/input-img/close2.png";
import Cookies from "js-cookie";

export const LoginTabComponent = ({ setShowLoginModal }) => {
  const { t } = useTranslation();
  const { auth } = useContext(Context);
  const navigate = useNavigate();
  const [inputType, setInputType] = useState("password");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleCloseModal = (e) => {
    if (e && e.target && e.target.classList.contains("modal-overlay")) {
      setShowLoginModal(false);
    }
    setShowLoginModal(false);
    closeHandler();
  };

  useEffect(() => {
    const storedEmail = Cookies.get("email");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      toast.success(t("logged_in"));

      // Зберігаємо email в cookies на 7 днів
      Cookies.set("email", email, { expires: 7 });

      handleCloseModal();
    } catch (error) {
      // Відловлювання конкретних помилок
      switch (error.code) {
        case "auth/user-not-found":
          toast.error(t("profile_not_found"));
          break;
        case "auth/invalid-credential":
          toast.error(t("invalid_credential"));
          break;
        case "auth/wrong-password":
          toast.error(t("wrong_password"));
          break;
        case "auth/too-many-requests":
          toast.error(t("too_many_requests"));
          break;
        // Додаткові випадки помилок можна додати тут
        default:
          toast.error(t("error_occurred"));
      }
      console.error(error);
    }
  };

  const togglePasswordVisibility = () => {
    setInputType(inputType === "password" ? "text" : "password");
  };

  const handlePassRecover = () => {
    navigate("/password-recovery");
    handleCloseModal();
  };

  return (
    <form className="loginForm" onSubmit={handleLogin}>
      <input
        className="loginForm__input"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder={t("email_address")}
        required
      />

      <div className="loginForm__input-container">
        <input
          type={inputType}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="loginForm__input loginForm__input--noShadow"
          placeholder={t("password")}
          required
        />
        <img
          className="loginForm__icon"
          onClick={togglePasswordVisibility}
          src={inputType === "password" ? closeEye : openEye}
          alt="Toggle visibility"
        />
      </div>

      <button type="submit" className="loginForm__btn">
        {t("sign_in")}
      </button>

      <p className="loginForm__link" onClick={handlePassRecover}>
        {t("forgot_password")}
      </p>
    </form>
  );
};
