import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { closeHandler } from "../../utils/dom/open-close-modal";
import { RegisterTabComponent } from "./tab-Register";
import { LoginTabComponent } from "./tab-Login";
import "../modalStyle.scss";

export const LoginModal = ({ showLoginModal, setShowLoginModal }) => {
  const [activeTab, setActiveTab] = useState("signIn");
  const { t } = useTranslation();
  const contentRef = useRef(null);

  const handleCloseModal = (e) => {
    if (e && e.target && e.target.classList.contains("modal-overlay")) {
      setShowLoginModal(false);
    }
    setShowLoginModal(false);
    closeHandler();
  };

  const handleTabChange = (tab) => {
    if (tab !== activeTab) {
      const content = contentRef.current;
      const prevHeight = content.offsetHeight;
      const prevWidth = content.offsetWidth;

      setActiveTab(tab);

      requestAnimationFrame(() => {
        const newHeight = content.scrollHeight;
        const newWidth = content.scrollWidth;

        content.style.height = `${prevHeight}px`;
        content.style.width = `${prevWidth}px`;
        content.style.transition = "height 0.3s ease, width 0.3s ease";

        requestAnimationFrame(() => {
          content.style.height = `${newHeight}px`;
          content.style.width = `${newWidth}px`;
        });

        content.addEventListener(
          "transitionend",
          () => {
            content.style.height = "";
            content.style.width = "";
            content.style.transition = "";
          },
          { once: true }
        );
      });
    }
  };

  return (
    <div
      className={`modal-overlay ${showLoginModal ? "show" : ""}`}
      onClick={handleCloseModal}
      style={{ visibility: showLoginModal ? "visible" : "hidden" }}
    >
      <section className="modal" onClick={(e) => e.stopPropagation()}>
        <span className="modal__cross" onClick={() => setShowLoginModal(false)}>
          &#10006;
        </span>
        <div className="modal__tabs">
          <button
            className={`modal__btn ${activeTab === "signIn" ? "active" : ""}`}
            onClick={() => handleTabChange("signIn")}
          >
            {t("login")}
            <span className="modal__span"></span>
          </button>
          <button
            className={`modal__btn ${activeTab === "signUp" ? "active" : ""}`}
            onClick={() => handleTabChange("signUp")}
          >
            {t("registration")}
            <span className="modal__span"></span>
          </button>
        </div>
        <div className="modal__content" ref={contentRef}>
          {activeTab === "signIn" ? (
            <LoginTabComponent setShowLoginModal={setShowLoginModal} />
          ) : (
            <RegisterTabComponent setShowLoginModal={setShowLoginModal} />
          )}
        </div>
      </section>
    </div>
  );
};
